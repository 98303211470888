import axios from 'axios'
import { getToken, removeToken } from '@/utils/auth'
import merge from 'lodash/merge'
import { Notify } from 'vant'
import qs from 'qs'

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
const http = axios.create({
  timeout: 1000 * 30,
  withCredentials: true,
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  // baseURL: process.env.VUE_APP_BASE_API,
  // baseURL: 'https://develop.zbt-api.422wsdq.com',
  baseURL: 'https://zbt-api.422wsdq.com',
  headers: {
    'Content-Type': 'application/json; charset=utf-8',
  },
})

http.interceptors.request.use(
  (config) => {
    config.headers['Authorization'] = getToken()
    if (config.method === 'post') {
      config.url = config.url + '?t=' + new Date().getTime()
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

/**
 * 响应拦截
 */
http.interceptors.response.use(
  (response) => {
    if (response.data.code === 0) {
      Notify({ type: 'danger', message: response.data.msg })
      return Promise.reject(response.data.msg)
    } else if (response.data.code === -1) {
      removeToken()
      Notify({ type: 'danger', message: '登录状态已过期' })
      location.href = '/'
      return Promise.reject('无效的会话，或者会话已过期，请重新登录。')
    }
    // code为1时返回响应数据体
    return response.data
  },
  (error) => {
    console.log(error)
    switch (error.response.status) {
      case 400:
        Notify({ type: 'danger', message: error.response.data })
        break
      case 401:
        location.href = '/'
        break
      case 405:
        Notify({ type: 'danger', message: '请求方式有误' })
        break
      case 500:
        Notify({
          type: 'danger',
          message: '服务器出了点小差，请稍后再试',
        })
        break
      case 501:
        Notify({
          type: 'danger',
          message: '服务器不支持当前请求所需要的某个功能',
        })
        break
    }
    return Promise.reject(error)
  }
)

/**
 * get请求参数处理
 * @param {*} params 参数对象
 * @param {*} openDefultParams 是否开启默认参数?
 */
http.adornParams = (params = {}, openDefultParams = true) => {
  var defaults = {
    t: new Date().getTime(),
  }
  return openDefultParams ? merge(defaults, params) : params
}

/**
 * post请求数据处理
 * @param {*} data 数据对象
 * @param {*} openDefultdata 是否开启默认数据?
 * @param {*} contentType 数据格式
 *  json: 'application/json; charset=utf-8'
 *  form: 'application/x-www-form-urlencoded; charset=utf-8'
 */
http.adornData = (data = {}, openDefultdata = true, contentType = 'json') => {
  var defaults = {
    t: new Date().getTime(),
  }
  data = openDefultdata ? merge(defaults, data) : data
  return contentType === 'json' ? JSON.stringify(data) : qs.stringify(data)
}

export default http
