<template>
  <div>
    <van-nav-bar :title="title" left-arrow @click-left="onClickLeft" />
  </div>
</template>

<script>
import { NavBar } from 'vant'
export default {
  name: 'NavBar',
  components: {
    [NavBar.name]: NavBar,
  },
  props: {
    title: String,
    close: Boolean
  },
  methods: {
    onClickLeft() {
      history.back()
    },
  },
}
</script>
<style lang="less">
:root {
  --van-nav-bar-icon-color: rgb(0, 0, 0);
}
</style>
