<template>
  <div class="container">
    <router-view />
  </div>
</template>

<script>
import { Tabbar, TabbarItem } from 'vant'
export default {
  components: {
    [Tabbar.name]: Tabbar,
    [TabbarItem.name]: TabbarItem,
  },
  data() {
    return {
      icons: [
        'https://dev-iot-v2.oss-cn-shenzhen.aliyuncs.com/market/picture/20220609_202204_62a1e5ecca1aa.png',
        'https://dev-iot-v2.oss-cn-shenzhen.aliyuncs.com/market/picture/20220609_201348_62a1e3fc39d51.png',
        'https://dev-iot-v2.oss-cn-shenzhen.aliyuncs.com/market/picture/20220609_202136_62a1e5d03338e.png',
        'https://dev-iot-v2.oss-cn-shenzhen.aliyuncs.com/market/picture/20220609_202231_62a1e6071cff5.png',
      ],
      homeIcon:
        'https://dev-iot-v2.oss-cn-shenzhen.aliyuncs.com/market/picture/20220609_201348_62a1e3fc39d51.png',
      myIcon:
        'https://dev-iot-v2.oss-cn-shenzhen.aliyuncs.com/market/picture/20220609_202136_62a1e5d03338e.png',
    }
  },
  methods: {
    changeTabbar(index) {
      if (index === 0) {
        this.homeIcon = this.icons[1]
        this.myIcon = this.icons[2]
      } else {
        this.homeIcon = this.icons[0]
        this.myIcon = this.icons[3]
      }
    },
  },
}
</script>
<style lang="less">
.van-notify {
  z-index: 99999 !important;
}
</style>
