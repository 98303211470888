import Cookies from 'js-cookie'

const TokenKey = 'Worker-Token'
const UserKey = 'Worker-Info'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function setId(user) {
  return Cookies.set(UserKey, user)
}

export function getId() {
  return Cookies.get(UserKey)
}

export function removeId() {
  return Cookies.remove(UserKey)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}
